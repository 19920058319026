var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{attrs:{"title":_vm.$tc('common.consumer', 2),"loading":!_vm.consumersLoaded}},[_c('div',{staticClass:"consumers-list"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"search-filter-wrapper"},[_c('el-input',{class:[_vm.search.length ? 'filled' : ''],attrs:{"prefix-icon":"el-icon-search","placeholder":_vm.$t('placeholders.search'),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.hasFilters)?_c('el-button',{staticClass:"ml-4",on:{"click":function($event){_vm.openFilter = !_vm.openFilter}}},[_vm._v(" "+_vm._s(_vm.$t("labels.filter"))+" ")]):_vm._e()],1),(
          [_vm.OrganizationTypes.RETAILER, _vm.OrganizationTypes.CONSUMER].includes(_vm.roles.organizationType) &&
            (_vm.roles.superuser || (_vm.roles.admin || _vm.roles.staff))
        )?[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.createModalVisible = true}}},[_c('SvgInjector',{attrs:{"src":require('@/assets/img/icons/add.svg')}}),_vm._v(" "+_vm._s(_vm.$t('common.add_new', { item: _vm.$tc('common.consumer', 1) }))+" ")],1)]:_vm._e(),_c('el-dialog',{attrs:{"visible":_vm.createModalVisible,"close-on-click-modal":true,"before-close":_vm.closeDialog,"title":_vm.$t('common.add_new', { item: _vm.$tc('common.consumer', 1) }),"width":"50%"},on:{"update:visible":function($event){_vm.createModalVisible=$event}}},[(_vm.isRouterAlive)?_c('ConsumerCrudForm',{attrs:{"id":"consumer-create-form","operation":"create"},on:{"onSuccess":function($event){return _vm.siteCreated($event)},"changeForm":function($event){return _vm.changeF($event)}}}):_vm._e()],1)],2),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.openFilter)?_c('div',{staticClass:"mt-4"},[_c('ConsumerListFilter',{attrs:{"filter-sets":_vm.filterSets},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1):_vm._e()]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('el-table',{attrs:{"data":_vm.filteredList,"row-key":"id","row-class-name":"cursor-pointer"},on:{"row-click":function (row) { return _vm.$router.push({ name: 'Consumer', params: { id: row.id } }); }}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('labels.name'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_c('span',{class:['status-indicator mr-2', row.is_active ? 'bg-success' : 'bg-gray']})]),_c('div',[_vm._v(" "+_vm._s(row.name)+" ")])])]}}])}),(!(_vm.roles.organizationType === _vm.OrganizationTypes.RETAILER))?_c('el-table-column',{attrs:{"prop":"retailer","label":_vm.$t('labels.retailer'),"sortable":"","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(row.retailer)+" ")])]}}],null,false,2327599506)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"location.city","label":_vm.$t('labels.city'),"sortable":"","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(row.location.city)+", "),_c('span',{staticClass:"text-gray"},[_vm._v(_vm._s(row.location.country))])])]}}])})],1)],1)]),_c('el-dialog',{attrs:{"title":_vm.$t('common.details'),"visible":_vm.detailsModalVisible},on:{"update:visible":function($event){_vm.detailsModalVisible=$event}}},[_c('ConsumerDetails',{attrs:{"details":_vm.consumerDetails},on:{"delete":function () {
          _vm.detailsModalVisible = false;
          _vm.consumersListDataFn();
        },"update":function () {
          _vm.detailsModalVisible = false;
          _vm.consumersListDataFn();
        }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }