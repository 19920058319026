




































































































































































import { defineComponent } from "@vue/composition-api";
import { getConsumersList } from "@/api/organizations/consumer";

import { IConsumerDetails } from "@/lib/types/organizations/consumer";
import { dynamicSort, seperateThousand, slugify } from "@/utils";
import { OrganizationTypes } from "@/lib/types/base";

export default defineComponent({
  name: "ConsumersList",
  components: {
    ConsumerListFilter: () => import("@/components/consumer/ConsumerListFilter.vue"),
    ConsumerCrudForm: () => import("@/components/forms/ConsumerCrudForm.vue"),
    ConsumerDetails: () => import("@/components/consumer/ConsumerDetails.vue"),
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if ([OrganizationTypes.RETAILER].includes(vm.$store.state.user.details.organization_type)) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    });
  },
  data () {
    return {
      consumers: [] as IConsumerDetails[],
      consumersLoaded: false,
      consumerDetails: null,
      limit: 24,
      offset: 0,
      search: "",
      openFilter: false,
      createModalVisible: false,
      detailsModalVisible: false,
      filters: {
        city: [] as string[],
        distance: [] as number[],
        retailer: [] as string[],
      },
      filterSets: {
        city: [] as string[],
        distance: [] as number[],
        retailer: [] as string[],
      },
      isRouterAlive: true,
      consumerCrudFormValue: false,
    };
  },
  computed: {
    hasFilters () {
      return Object.values(this.filterSets as Record<string, any[]>).some((element: any[]) => {
        return element.length > 1;
      });
    },
    roles () {
      return this.$store.getters["user/roles"];
    },
    filteredList () {
      let results = this.consumers
        ? [...(this.consumers as IConsumerDetails[]).sort(dynamicSort("-created_at"))]
        : [];

      if (this.search.length) {
        results = results.filter(res => {
          return (
            slugify(res.name).includes(slugify(this.search)) ||
            slugify(res.blockchain_account_address).includes(slugify(this.search)) ||
            slugify(res.id).includes(slugify(this.search))
          );
        });
      }

      if (this.filters.city.length) {
        results = results.filter(res => {
          return this.filters.city.includes(res.location.city);
        });
      }

      return results;
    },
  },
  methods: {
    async consumersListDataFn () {
      this.consumersLoaded = false;

      await getConsumersList({
        limit: Number.MAX_SAFE_INTEGER,
        offset: this.offset,
      })
        .then(res => {
          if (res.results) {
            this.consumers = res.results.sort(dynamicSort("-created_at"));
          }

          this.consumersLoaded = true;
        })
        .catch(err => alert(JSON.stringify(err.body.errors.detail)));
    },
    siteCreated () {
      this.consumersListDataFn();
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
      this.createModalVisible = false;
    },
    siteUpdated () {
      this.consumersListDataFn();
      this.createModalVisible = false;
    },
    openDetailsModal (row: any) {
      this.detailsModalVisible = true;
      this.consumerDetails = row;
    },
    changeF(newVal: any) {
      this.consumerCrudFormValue = newVal;
    },
    closeDialog() {
      if (this.consumerCrudFormValue) {
        this.$confirm(
        /* Message */
        this.$i18n.t("messages.sure_to_close") as string,
        /* Title */
        this.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: "OK",
          cancelButtonText: this.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              this.isRouterAlive = false;
              this.$nextTick(() => {
                this.isRouterAlive = true;
              });
              this.createModalVisible = false;
              instance.confirmButtonLoading = false;
              done();
              /*  */
            } else {
              done();
            }
          },
        });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.createModalVisible = false;
        this.consumerCrudFormValue = false;
      }
    },
  },
  mounted () {
    this.consumersListDataFn();
  },
  watch: {
    consumers: {
      handler (newVal) {
        if (newVal) {
          const citySet = new Set<string>();
          const distanceSet = new Set<number>();
          const retailerSet = new Set<string>();

          this.consumers.forEach(consumer => {
            citySet.add(consumer.location.city);
            retailerSet.add(consumer.retailer ? consumer.retailer : "-");
            distanceSet.add(consumer.energy_source_max_distance as number);
          });

          this.filterSets.city = [...citySet].sort();
          this.filterSets.distance = [...distanceSet].sort();
          this.filterSets.retailer = [...retailerSet].sort();
        }
      },
      deep: true,
    },
  },
  setup () {
    return { seperateThousand, OrganizationTypes };
  },
});
